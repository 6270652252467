<template>
  <div :class="`m-3 p-2 wrapper  wrapper--${bu}`">
    <div class="text-center p-2">
      <h2>Voertuig Wijzigingen</h2>
      <span @click="previousWeek"><i class="fas fa-arrow-left"></i></span>
      <span class="text-center m-2"
        >Week: {{ week_number }}, {{ year_number }}</span
      >
      <span @click="nextWeek"><i class="fas fa-arrow-right"></i></span>
    </div>
    <Loading v-if="loading" />
    <table v-if="!loading" :class="`w-100 table--perweek table__border--${bu}`">
      <thead :class="`table__head--${bu}`">
        <tr>
          <th class="table__head--text">Foto</th>
          <th class="table__head--text">Item</th>
          <th class="table__head--text">Inkoper</th>
          <th class="table__head--text">Datum</th>
          <th class="table__head--text">Wijziging</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, key) in vehicle_changes"
          :key="key"
          :class="`table__row--color`"
        >
          <td class="table__cell--default">
            <img
              :src="`https://apps.kleyn.com:1919/prod/public/data/mainimage/${item.Itemnummer}/original`"
              :class="`m-1 border border-secondary table__picture--max-width`"
            />
          </td>
          <td class="table__cell--default">
            <ImageHover :itemnummer="item.Itemnummer" :bu="bu" />
            {{ item.soortnaam }}<br />
            {{ item.opbouwnaam }}<br />
            {{ item.merknaam }}<br />
            {{ item.typenaam }}<br />
            {{ item.regjaar }}
          </td>
          <td class="table__cell--default">{{ item.naam }}</td>
          <td class="table__cell--default">
            {{ item.Datum }}
          </td>
          <td class="table__cell--default">
            {{ item.veldnaam }}<br />
            <span v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"> Van: <strong>{{ item.oud }}</strong
            ></span><br />
            <span v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)">Naar: <strong>{{ item.nieuw }}</strong></span>
          </td>
        </tr>
      </tbody>
      <tfoot class="table__foot">
        <tr>
          <th class="table__foot--padding">Totaal:</th>
          <th class="table__foot--padding">{{ total_changes.total }}</th>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import request from "@/functions/request.js";
import Loading from "@/components/Loading.vue";
import ImageHover from "@/components/ImageHover.vue";
import moment from "moment";
import { addTime, subtractTime } from "@/functions/date.js";


export default {
  props: ["bu"],
  components: { ImageHover, Loading },
  data: () => ({
    loading: null,
    vehicle_changes: null,
    total_changes: null,
    week_number: null,
    year_number: null,
    full_date: null,
  }),
  created() {
    this.full_date = moment();
    this.getData(this.bu);
  },
  watch: {
    bu(newbu) {
      this.getData(newbu);
    },
  },
  methods: {
    getData(bu) {
      this.loading = true;
      this.week_number = this.full_date.week();
      this.year_number = this.full_date.year();
      request(
        `vehicle-changes/${bu}/${this.year_number}/${this.week_number}`,
        "GET"
      ).then(({ vehicle_changes, total_changes }) => {
        this.vehicle_changes = vehicle_changes;
        this.total_changes = total_changes;
        this.loading = false;
      });
    },

    previousWeek() {
      this.full_date = subtractTime(1, this.full_date, "weeks");
      this.getData(this.bu);
    },

    nextWeek() {
      this.full_date = addTime(1, this.full_date, "weeks");
      this.getData(this.bu);
    },

    
  },
};
</script>
